import { Col, Form, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { Option, OptionDetails } from 'api/api_code'
import { OptionsEndpoints } from 'api/axios/axiosAPIGroups'
import clsx from 'clsx'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { InputNumber } from 'components/UI/InputNumber/InputNumber'
import { Select } from 'components/UI/Select/Select'
import { ScoreBoardConfig } from 'constants/scoreboards'
import { get } from 'helpers/utils'
import { useFieldConfig } from 'hooks/useFieldConfig'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { DragAndDrop } from '../UI/DragAndDrop/DragAndDrop'
import styles from './SmartScoreboardConfig.module.css'

export interface ScoreboardOcrDTO {
  filename: string
  content_type: string
  data: string | ArrayBuffer
}

export const SmartScoreboardConfig = () => {
  const { t } = useTranslation('fieldConfig')
  const { fieldConfig, setFieldConfig } = useFieldConfig()
  const { requestHandler } = useHTTPRequestHandler()
  const router = useRouter()
  const device_uuid = router.query['device_uuid'] as string
  const [form] = Form.useForm()

  const [selects, setSelects] = useState<{ [key: string]: OptionDetails }>({})
  const noneOpt: Option = { value: 'none', display_name: 'None', validation_regex: null }

  useEffect(() => {
    requestHandler({
      requestPromise:
        OptionsEndpoints.getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(device_uuid),
      onOkCallback: (selects) => {
        setSelects({
          ...selects,
          graphic_plugin: {
            ...selects.graphic_plugin,
            opts: [...selects.graphic_plugin.opts, noneOpt],
          },
        })
      },
    })
  }, [])

  const deleteFile = (fileName: string) => {
    const actualOCRFiles = get(fieldConfig, 'scoreboard_configuration.ocr_files', []).filter(
      (ORCFile) => ORCFile.filename !== fileName
    )

    let deleteOCRList: string[]
    if (fieldConfig.scoreboard_configuration.ocr_files_to_delete !== null) {
      deleteOCRList = [...fieldConfig.scoreboard_configuration.ocr_files_to_delete, fileName]
    } else {
      deleteOCRList = [fileName]
    }

    setFieldConfig({
      ...fieldConfig,
      scoreboard_configuration: {
        ...get(fieldConfig, 'scoreboard_configuration', {}),
        ocr_files: actualOCRFiles,
        ocr_files_to_delete: deleteOCRList,
      },
    })
  }

  const saveOrUpdateFiles = (files: File[]) => {
    files.map((file) => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (event) => {
        const actualOCRFiles = get(fieldConfig, 'scoreboard_configuration.ocr_files', [])
        const index = actualOCRFiles.findIndex((ORCFile) => ORCFile.filename === file.name)
        if (index !== -1) {
          actualOCRFiles[index] = {
            filename: file.name,
            data: event.target!.result,
          }
        } else {
          actualOCRFiles.push({
            filename: file.name,
            data: event.target!.result,
          })
        }
        setFieldConfig({
          ...fieldConfig,
          scoreboard_configuration: {
            ...get(fieldConfig, 'scoreboard_configuration', {}),
            ocr_files: actualOCRFiles,
          },
        })
      }
    })
  }

  const scoreboardInputPlugin = get(fieldConfig, 'scoreboard_configuration.input_plugin', false)
  const scoreboardInputIsSCOREHUBOrSCORELINK =
    !scoreboardInputPlugin ||
    scoreboardInputPlugin === ScoreBoardConfig.SCOREHUB ||
    scoreboardInputPlugin === ScoreBoardConfig.SCORELINK ||
    scoreboardInputPlugin === 'none'
  const scoreboardFilesStyles = clsx({
    [styles.flexcol]: !scoreboardInputIsSCOREHUBOrSCORELINK,
    [styles.hidden]: scoreboardInputIsSCOREHUBOrSCORELINK,
  })

  return (
    fieldConfig && (
      <div className={styles.atvgraph}>
        <div className={styles.explanation}>
          <Paragraph className={`body-medium`}>
            {t('scoreboard_configuration.explanationText')}
          </Paragraph>
        </div>
        <Form
          layout="vertical"
          className={styles.form}
          requiredMark={false}
          aria-label="smart scoreboard form"
          form={form}
        >
          <Row justify={'space-between'}>
            <Col>
              {Object.keys(selects).map((key) => {
                const select = selects[key]
                const label = t(`scoreboard_configuration.${key}.label`, {
                  fallback: select.name,
                })
                return (
                  <Form.Item
                    key={'input_plugin'}
                    label={label}
                    name={key}
                    className={styles.plugin}
                    aria-label={'Graphic plugin'}
                  >
                    <Select
                      placeholder={t(`scoreboard_configuration.${key}.placeholder`, {
                        fallback: select.name,
                      })}
                      defaultValue={
                        key === 'graphic_plugin'
                          ? fieldConfig.scoreboard_configuration?.['input_plugin'] ?? 'none'
                          : null
                      }
                      onChange={(value) => {
                        setFieldConfig({
                          ...fieldConfig,
                          scoreboard_configuration: {
                            ...get(fieldConfig, 'scoreboard_configuration', {}),
                            [key === 'graphic_plugin' ? 'input_plugin' : key]: value,
                          },
                        })
                      }}
                      options={select.opts.map((item) => ({
                        value: item.value,
                        label: item.display_name,
                      }))}
                    ></Select>
                  </Form.Item>
                )
              })}
            </Col>
          </Row>
          <Row className={scoreboardFilesStyles}>
            <Form.Item
              label={t('scoreboard_configuration.ocr_files.label')}
              aria-label="scoreboard ocr configuration files"
              name={'ocr_files'}
              tooltip={{
                title: t('scoreboard_configuration.ocr_files.tooltip'),
                icon: <Icon className="ml-1" component={IconNames.INFO} size={14} />,
              }}
            >
              <DragAndDrop
                disabled={fieldConfig.scoreboard_configuration?.input_plugin !== 'ocr'}
                filesList={get(fieldConfig, 'scoreboard_configuration.ocr_files', []).map(
                  (file) => file.filename
                )}
                filesExtension={['ocr']}
                toBeDeletedCallback={deleteFile}
                setFilesToSendCallback={saveOrUpdateFiles}
                overrideExistingFilesCallback={saveOrUpdateFiles}
              />
            </Form.Item>
          </Row>
        </Form>
      </div>
    )
  )
}
