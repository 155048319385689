import { Button, Modal, Space } from 'antd'
import { DeviceConfigurationEndpoints } from 'api/axios/axiosAPIGroups'
import { useUnsavedChanges } from 'hooks/useUnsavedChanges'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styles from './UnsavedChangesModal.module.css'

export const UnsavedChangesModal = () => {
  const { t } = useTranslation('common')
  const { unsavedChanges } = useUnsavedChanges()
  const [open, setOpen] = useState(false)
  const [nextPath, setNextPath] = useState('')
  const [confirm, setConfirm] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const warningText = t('unsavedChanges')
    //TODO: TAKE A LOOK TO THIS FUNCTION
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      const device_uuid = router.query['device_uuid']
      // Close device configuration session
      DeviceConfigurationEndpoints.stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(
        device_uuid as string
      )
      if (!unsavedChanges) return
      e.preventDefault()
      return (e.returnValue = warningText)
    }

    const handleBrowseAway = (nextPath: string) => {
      if (!unsavedChanges) return
      if (confirm) return
      else {
        setOpen(true)
        setNextPath(nextPath)
      }

      router.events.emit('routeChangeError')
      throw 'routeChange aborted.'
    }

    // window.addEventListener('beforeunload', handleWindowClose)

    router.events.on('routeChangeStart', handleBrowseAway)

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
      router.events.off('routeChangeStart', handleBrowseAway)
    }
  }, [unsavedChanges, confirm])

  return (
    <Modal centered open={open} footer={null} width={438}>
      <div className={styles.icon}></div>
      <h2 className="textCenter my-2 bold">{`${t('wait')}!`}</h2>
      <p className="body-large textCenter mt-2 mb-6">{t('unsavedChanges')}</p>
      <div className={styles.footer}>
        <Space size={'middle'}>
          <Button style={{ minWidth: '127px' }} onClick={() => setOpen(false)}>
            {t('back')}
          </Button>
          <Button
            style={{ minWidth: '127px' }}
            key="logout"
            onClick={() => {
              setConfirm(true)
              setOpen(false)
              router.push(nextPath)
            }}
            danger
          >
            {t('logOut')}
          </Button>
        </Space>
      </div>
    </Modal>
  )
}
