import { Select as AntSelect, SelectProps } from 'antd'
import { Icon } from '../Icon/Icon'
import { IconNames } from '../Icon/icons'

const { Option } = AntSelect
const Select = (props: SelectProps) => {
  return (
    <AntSelect
      {...props}
      placement="bottomLeft"
      dropdownAlign={{ offset: [0, -4] }}
      defaultActiveFirstOption={false}
      suffixIcon={
        <Icon component={IconNames.CHEVRON_DOWN} size={24} className="ant-select-suffix" />
      }
    >
      {props.children}
    </AntSelect>
  )
}

export { Select, Option }
