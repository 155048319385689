import { Button, Form, Modal as AntModal, ModalProps } from 'antd'
import Title from 'antd/lib/typography/Title'
import { SportDTO } from 'api/api_code'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { Icon } from '../../Icon/Icon'
import { IconNames } from '../../Icon/icons'
import { Select } from '../../Select/Select'
import styles from './SelectSportFieldModal.module.css'

export interface SelectSportFieldModalProps extends ModalProps {
  sport: SportDTO
  onSelectSportField: (uuid) => void
  onCancel: () => void
}

const SelectSportFieldModal = ({
  sport,
  onSelectSportField: sendSportToConfList,
  onCancel: onCloseModal,
}: SelectSportFieldModalProps) => {
  const { t } = useTranslation('fieldConfig')
  const [selectedSportField, setSelectedSportField] = useState<string>(
    sport?.sportfields[0].value ?? ''
  )

  useEffect(() => {
    setSelectedSportField(sport?.sportfields[0].value)
  }, [sport])

  const handleChange = (sportField: string) => {
    setSelectedSportField(sportField)
  }

  return (
    <AntModal
      open={sport !== null}
      centered
      width={438}
      footer={null}
      onCancel={onCloseModal}
      closeIcon={<Icon component={IconNames.CROSS} size={24}></Icon>}
    >
      <Title level={2} className={styles.modalTitle}>
        {t('configured_sports.selectSportFieldModalTitle')}
      </Title>
      <div className={styles.antmodalbody}>
        <Form layout="vertical">
          <Form.Item label="Sportfield" aria-label="Select sportfield">
            <Select
              defaultValue={sport?.sportfields[0].value}
              style={{ width: 325 }}
              popupClassName={styles.sportFieldSelect}
              onChange={handleChange}
              options={sport?.sportfields}
            />
          </Form.Item>
        </Form>

        <Button
          type="primary"
          aria-label="Configure sportfield"
          onClick={() => sendSportToConfList(selectedSportField)}
          className={styles.sportFieldBtn}
        >
          {t('configured_sports.startCalibrationButton')}
        </Button>
      </div>
    </AntModal>
  )
}
export { SelectSportFieldModal }
