import { createContext, useState } from 'react'

export type UnsavedChangesContextType = {
  unsavedChanges: boolean
  setUnsavedChanges(unsavedChanges: boolean): void
}

interface UnsavedChangesContextProviderProps {
  children: React.ReactNode
}

export const UnsavedChangesContext = createContext<UnsavedChangesContextType>({
  unsavedChanges: false,
  setUnsavedChanges: () => ({}),
})

export function UnsavedChangesContextProvider({ children }: UnsavedChangesContextProviderProps) {
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  return (
    <UnsavedChangesContext.Provider value={{ unsavedChanges, setUnsavedChanges }}>
      {children}
    </UnsavedChangesContext.Provider>
  )
}
