import { DeviceConfigurationDTO } from 'api/api_code'
import { fieldConfigurationSteps } from 'core/field_configuration'
import { getFromLocalStorage, setToLocalStorage } from 'helpers/storageUtils'
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IndexSignature, Step } from 'types/global'

export interface FieldConfigContextType {
  currentStep: Step
  setCurrentStep(currentStep: Step): void
  fieldConfig: DeviceConfigurationDTO
  setFieldConfig: Dispatch<SetStateAction<DeviceConfigurationDTO>>
  fieldConfigBE: DeviceConfigurationDTO
  setFieldConfigBE(fieldConfigBE: DeviceConfigurationDTO): void
  configuredSports: Map<string, IndexSignature>
  setConfiguredSports: Dispatch<SetStateAction<Map<string, IndexSignature>>>
  availableSportsMap: Map<string, IndexSignature>
  setAvailableSportsMap: Dispatch<SetStateAction<Map<string, IndexSignature>>>
  configuredSportsMap: Map<string, number>
  setConfiguredSportsMap: Dispatch<SetStateAction<Map<string, number>>>
}

interface FieldConfigContextProviderProps {
  children: React.ReactNode
}

export const FieldConfigContext = createContext<FieldConfigContextType>({
  currentStep: fieldConfigurationSteps[0],
  setCurrentStep: () => ({}),
  fieldConfig: null,
  setFieldConfig: () => ({}),
  fieldConfigBE: null,
  setFieldConfigBE: () => ({}),
  configuredSports: new Map<string, IndexSignature>(),
  setConfiguredSports: () => ({}),
  availableSportsMap: new Map<string, IndexSignature>(),
  setAvailableSportsMap: () => ({}),
  configuredSportsMap: new Map<string, number>(),
  setConfiguredSportsMap: () => ({}),
})

export function FieldConfigContextProvider({ children }: FieldConfigContextProviderProps) {
  const [currentStep, setCurrentStep] = useState<Step>(fieldConfigurationSteps[0])
  const [fieldConfig, setFieldConfig] = useState<DeviceConfigurationDTO>(
    getFromLocalStorage('field-configuration')
      ? JSON.parse(getFromLocalStorage('field-configuration'))
      : null
  )
  const [fieldConfigBE, setFieldConfigBE] = useState<DeviceConfigurationDTO>(null)
  // TODO: Change Object for DTO
  const [configuredSports, setConfiguredSports] = useState<Map<string, IndexSignature>>(
    new Map<string, IndexSignature>()
  )
  const [availableSportsMap, setAvailableSportsMap] = useState<Map<string, IndexSignature>>(
    new Map<string, IndexSignature>()
  )
  const [configuredSportsMap, setConfiguredSportsMap] = useState<Map<string, number>>(
    new Map<string, number>()
  )

  useEffect(() => {
    setToLocalStorage('field-configuration', JSON.stringify(fieldConfig))
  }, [fieldConfig])

  return (
    <FieldConfigContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        fieldConfig,
        setFieldConfig,
        fieldConfigBE,
        setFieldConfigBE,
        configuredSports,
        setConfiguredSports,
        availableSportsMap,
        setAvailableSportsMap,
        configuredSportsMap,
        setConfiguredSportsMap,
      }}
    >
      {children}
    </FieldConfigContext.Provider>
  )
}
