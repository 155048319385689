import { FieldConfigContext } from 'context/FieldConfigContext'
import { useContext } from 'react'

export function useFieldConfig() {
  const {
    currentStep,
    setCurrentStep,
    fieldConfig,
    setFieldConfig,
    fieldConfigBE,
    setFieldConfigBE,
    configuredSports,
    setConfiguredSports,
    availableSportsMap,
    setAvailableSportsMap,
    configuredSportsMap,
    setConfiguredSportsMap,
  } = useContext(FieldConfigContext)
  return {
    currentStep,
    setCurrentStep,
    fieldConfig,
    setFieldConfig,
    fieldConfigBE,
    setFieldConfigBE,
    configuredSports,
    setConfiguredSports,
    availableSportsMap,
    setAvailableSportsMap,
    configuredSportsMap,
    setConfiguredSportsMap,
  }
}
