import { Form, Input } from 'antd'
import styles from './FieldSettings.module.css'
import useTranslation from 'next-translate/useTranslation'
import { Select } from 'components/UI/Select/Select'
import { useFieldConfig } from 'hooks/useFieldConfig'
import { get } from 'helpers/utils'
import { useEffect, useMemo, useState } from 'react'
import { useLoading } from 'hooks/useLoading'
import { OptionDetails } from 'api/api_code'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { useRouter } from 'next/router'
import { OptionsEndpoints } from 'api/axios/axiosAPIGroups'

export const FieldSettings = () => {
  const { t } = useTranslation('fieldConfig')
  const { loading } = useLoading()
  const router = useRouter()
  const { fieldConfig, setFieldConfig } = useFieldConfig()
  const { t: tCommon } = useTranslation('common')
  const device_uuid = router.query['device_uuid']
  const [inputs, setInputs] = useState<Map<string, OptionDetails>>(new Map<string, OptionDetails>())
  const [form] = Form.useForm()

  const fieldsToShow = new Set(['type', 'timezone'])
  const { requestHandler } = useHTTPRequestHandler()

  const fieldValues = useMemo(
    () => ({
      'field-name': fieldConfig?.details?.name ?? '',
      timezone: fieldConfig?.details?.timezone ?? null,
      type: fieldConfig?.details?.type ?? null,
    }),
    [fieldConfig]
  )

  useEffect(() => {
    requestHandler({
      requestPromise: OptionsEndpoints.getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(
        device_uuid as string
      ),
      onOkCallback: (inputs) => {
        fieldsToShow.forEach((inputKey) =>
          setInputs(
            (prevInputs) =>
              new Map<string, OptionDetails>(prevInputs.set(inputKey, inputs[inputKey]))
          )
        )
        form.setFieldsValue(fieldValues)
      },
    })
  }, [])

  useEffect(() => {
    form.setFieldsValue(fieldValues)
  }, [fieldConfig])

  return (
    <div className={styles.content}>
      <div className={styles.box}>
        <Form
          layout="vertical"
          className={styles.form}
          requiredMark={false}
          aria-label="Field details form"
          form={form}
          initialValues={fieldValues}
        >
          {!loading && (
            <>
              <Form.Item
                label={t('details.name.label')}
                name="field-name"
                aria-label="Field name"
                rules={[
                  {
                    required: true,
                    message: tCommon('errors.inputMessage', {
                      fieldName: t('details.name.label'),
                    }),
                  },
                  {
                    max: 50,
                    message: tCommon('errors.lengthMessage', {
                      fieldName: t('details.name.label'),
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={t('details.name.placeholder')}
                  onChange={(e) => {
                    setFieldConfig({
                      ...fieldConfig,
                      details: { ...get(fieldConfig, 'details', {}), name: e.target.value },
                    })
                  }}
                />
              </Form.Item>
              {[...inputs.entries()].map((input) => {
                const [inputKey, inputOptions] = input
                const label = t(`details.${inputKey}.label`, { fallback: inputOptions?.name })
                return (
                  <Form.Item
                    key={`input-${inputOptions?.name ?? inputKey}`}
                    label={label}
                    name={inputKey}
                    aria-label={label}
                    rules={[
                      {
                        required: true,
                        message: tCommon('errors.selectMessage', { fieldName: label }),
                      },
                    ]}
                  >
                    <Select
                      placement="bottomLeft"
                      placeholder={t(`details.${inputKey}.placeholder`, {
                        fallback: inputOptions?.name,
                      })}
                      options={inputOptions?.opts?.map((item) => ({
                        value: item.value,
                        label: item.value,
                      }))}
                      onChange={(value) => {
                        setFieldConfig({
                          ...fieldConfig,
                          details: { ...fieldConfig.details, [inputKey]: value },
                        })
                      }}
                    />
                  </Form.Item>
                )
              })}
            </>
          )}
        </Form>
      </div>
    </div>
  )
}
