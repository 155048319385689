import { Button, Col, Modal as AntModal, ModalProps } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import { SportDTO, SportGroupDTO } from 'api/api_code'
import { getSportIllus } from 'helpers/utils'
import useTranslation from 'next-translate/useTranslation'
import { Icon } from '../../Icon/Icon'
import { IconNames } from '../../Icon/icons'
import styles from './SubSportModal.module.css'

export interface SubSportModalProps extends ModalProps {
  sport: SportGroupDTO
  configuredUuids: Set<string>
  onSelectSubSport: (index: number) => void
  onCancel: () => void
}

const SubSportModal = ({
  sport,
  configuredUuids,
  onSelectSubSport: onSelectSubSportIndex,
  onCancel: onCloseModal,
}: SubSportModalProps) => {
  const { t } = useTranslation('fieldConfig')

  /**
   * Checks if the sport was previously configured or if it's been configured.
   * @param subSport Sport data
   */
  const sportIsConfigured = (subSport: SportDTO) => {
    return configuredUuids.has(subSport.uuid)
  }

  return (
    <AntModal
      open={sport !== null}
      centered
      width={438}
      footer={null}
      onCancel={onCloseModal}
      closeIcon={<Icon component={IconNames.CROSS} size={24}></Icon>}
      className={styles.container}
    >
      <Title level={2} className={styles.title}>
        {`${t('configured_sports.selectSubSportModalTitle')} 
    ${sport?.name.toLowerCase()}`}
      </Title>
      <div className={styles.row}>
        {sport?.sports.map((subSport, index) => (
          <Col
            key={subSport.uuid}
            data-testid={`subsport-${subSport.name}`}
            className={
              sportIsConfigured(subSport) ? `${styles.cardDisabled} m-1` : `${styles.card} m-1`
            }
          >
            <Icon
              component={getSportIllus(sport.name)}
              className={styles.sportIcon}
              size={56}
            ></Icon>
            <Button
              type="primary"
              size="small"
              disabled={sportIsConfigured(subSport)}
              aria-label="Select sub sport to configure"
              className={styles.btn}
              onClick={() => onSelectSubSportIndex(index)}
            >
              {t('configured_sports.calibrateButton')}
            </Button>
            <Paragraph>{subSport.name.toUpperCase()}</Paragraph>
          </Col>
        ))}
      </div>
    </AntModal>
  )
}
export { SubSportModal }
