import AntIcon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { HTMLAttributes } from 'react'

type OnlyOnClick = Pick<HTMLAttributes<HTMLElement>, 'onClick'>
interface IconProps extends Partial<CustomIconComponentProps>, OnlyOnClick {
  /** Rendered SVG size, defaults to fit available space */
  size?: number

  /** SVG Icon component */
  component: any

  data_testid?: string
}

const Icon = ({ size, component, data_testid, style, ...props }: IconProps) => {
  const iconInlineStyles = {
    ...(size && { fontSize: `${size}px` }),
    ...(style && style),
  }
  return (
    <AntIcon data-testid={data_testid} component={component} style={iconInlineStyles} {...props} />
  )
}

export { Icon, type IconProps }
