import { DeviceDTO } from 'api/api_code'
import {
  IconType,
  NotificationModal,
  OpenNotificationProps,
} from 'components/UI/Modals/NotificationModal'
import { useLoading } from 'hooks/useLoading'
import { createContext, Dispatch, SetStateAction, useState } from 'react'

export interface GlobalContextType {
  device: DeviceDTO
  setDevice: (device: DeviceDTO) => void
  hasBackground: boolean
  setHasBackground: (hasBackground: boolean) => void
  openNotification: OpenNotificationProps
  setOpenNotification: Dispatch<SetStateAction<OpenNotificationProps>>
  storageLimitException: boolean
  setStorageLimitException: Dispatch<SetStateAction<boolean>>
}

export interface GlobalContextProviderProps {
  children: React.ReactNode
}

export const GlobalContext = createContext<GlobalContextType>({
  device: null,
  setDevice: () => ({}),
  hasBackground: false,
  setHasBackground: () => ({}),
  openNotification: {
    open: false,
    titleIconType: IconType.INFO,
  },
  setOpenNotification: () => ({}),
  storageLimitException: false,
  setStorageLimitException: () => ({}),
})

export function GlobalContextProvider({ children }: GlobalContextProviderProps) {
  const [hasBackground, setHasBackground] = useState<boolean>(false)
  const [device, setDevice] = useState<DeviceDTO>(null)
  const [openNotification, setOpenNotification] = useState<OpenNotificationProps>({
    open: false,
    titleIconType: IconType.INFO,
  })
  const { setLoading } = useLoading()
  const [storageLimitException, setStorageLimitException] = useState<boolean>(false)

  const onCloseNotification = () => {
    setOpenNotification({
      open: false,
    })
    setLoading(false)
  }
  return (
    <GlobalContext.Provider
      value={{
        device,
        setDevice,
        hasBackground,
        setHasBackground,
        openNotification,
        setOpenNotification,
        storageLimitException,
        setStorageLimitException,
      }}
    >
      <NotificationModal
        onOk={onCloseNotification}
        onCancel={onCloseNotification}
        {...openNotification}
      />
      {children}
    </GlobalContext.Provider>
  )
}
