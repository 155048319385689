
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { Loading } from 'components/Loading/Loading'
import { LoadingContextProvider } from 'context/LoadingContext'
import type { AppProps } from 'next/app'
import 'styles/theme.css'
import { GlobalContextProvider } from 'context/GlobalContext'
import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import { AuthContextProvider } from '../context/AuthContext'
import { FieldConfigContextProvider } from 'context/FieldConfigContext'
import { UnsavedChangesModal } from 'components/UI/Modals/UnsavedChangesModal/UnsavedChangesModal'

type ComponentWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface PageWithLayout extends AppProps {
  Component: ComponentWithLayout
}

const __Page_Next_Translate__ = function App({ Component, pageProps }: PageWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <>
      <Head>
        <title>ATV Wizard</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <AuthContextProvider>
        <LoadingContextProvider>
          <GlobalContextProvider>
            <FieldConfigContextProvider>
              <UnsavedChangesModal />
              {getLayout(<Component {...pageProps} />)}
              <Loading />
            </FieldConfigContextProvider>
          </GlobalContextProvider>
        </LoadingContextProvider>
      </AuthContextProvider>
    </>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  