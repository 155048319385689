import React, { useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Icon } from '../Icon/Icon'
import { IconNames } from '../Icon/icons'
import { Button, Tag } from 'antd'
import styles from './DragAndDrop.module.css'
import { IconType, NotificationModal } from '../Modals/NotificationModal'
import clsx from 'clsx'

export interface DragDropProps {
  /** Display name for the drag and drop component */
  title?: string

  /** Copy of the filelist received from the backend with/out modifications */
  filesList?: string[]

  /** File extension type filtering for the input */
  filesExtension?: string[]

  /** If set, means the form control accepts one or more values */
  multiple?: boolean

  /** When selected "none" option, should disable drag and drop */
  disabled?: boolean
  /**
   * Modifies uploaded "filesList"
   * @param {string | string[]} files files list names
   */
  updateFilesListCallback?: (files: string | string[]) => void

  /**
   * Modifies "to be deleted" files list
   * @param {string} filename selected file to be deleted
   */
  toBeDeletedCallback: (fileName: string) => void

  /**
   * Modifies the actual list of Files
   * @param {File[]} fileList uploaded list of files from the input
   */
  setFilesToSendCallback: (filesList: File[]) => void

  /**
   * Overrides the exisiting file from the "to be sent" list
   * @param {File[]} overridingFiles list of files to be updated with
   */
  overrideExistingFilesCallback: (overridingFiles: File[]) => void
}

export const DragAndDrop = ({
  filesList = [],
  filesExtension = ['*'],
  multiple = true,
  disabled = false,
  updateFilesListCallback,
  toBeDeletedCallback,
  setFilesToSendCallback,
  overrideExistingFilesCallback,
}: DragDropProps) => {
  const { t } = useTranslation('fieldConfig')
  const dragDropInput = useRef<HTMLInputElement>(null)
  const dragDropOverlayRef = useRef<HTMLDivElement>(null)
  const [dragOver, setDragOver] = useState<boolean>(false)

  let files: File[] = []
  const [repeatedFiles, setRepeatedFiles] = useState<File[]>([])

  // TODO: Change any
  const filesHandler = (event: any) => {
    dragDropOverlayRef.current.style.visibility = 'hidden'
    if (event.type === 'drop') {
      event.preventDefault()
      // Reset drag over styles
      setDragOver(false)
      // Save files to the buffer
      files = Array.from(event.dataTransfer.files)
      // Check if filenames are being overriden
      checkOverridingFileNames(event.dataTransfer.files)
      // Reset selected file list
      dragDropInput.current.value = null
      return
    }
    // Save files to the buffer
    files = Array.from(event.target.files)
    checkOverridingFileNames(event.target.files)
    dragDropInput.current.value = null
  }

  /**
   * Checks and compares the imported files' names
   * with the previous list.
   * @param {FileList} files Dropped & selected files
   */
  const checkOverridingFileNames = (files: FileList) => {
    const alertArray = []
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name
      if (filesList.includes(fileName)) {
        // Add repeated file to the alert array
        alertArray.push(files[i])
        continue
      }
      if (updateFilesListCallback) {
        updateFilesListCallback(fileName)
      }
      // Add to the "to be sent" files list
      setFilesToSendCallback([files[i]])
    }

    // Show warning popup
    if (alertArray.length > 0) {
      setRepeatedFiles(alertArray)

      setOpenModal(true)
    }
  }

  const [openModal, setOpenModal] = useState(false)

  const handleOk = async (e: React.MouseEvent<HTMLElement>) => {
    overrideExistingFilesCallback(repeatedFiles)
    setOpenModal(false)
  }

  const handleCancel = async (e: React.MouseEvent<HTMLElement>) => {
    const uploadedFiles = Array.from(files)
    let uploadedFilesCopy = [...uploadedFiles]

    for (let i = 0; i < repeatedFiles.length; i++) {
      const repeatedFileName = repeatedFiles[i].name
      uploadedFilesCopy = uploadedFilesCopy.filter((file) => {
        return file.name !== repeatedFileName
      })
    }

    // Append the new files to the "to be uploaded" files list
    setFilesToSendCallback(uploadedFilesCopy)
    files = []
    setOpenModal(false)
  }

  /**
   * Deletes the selected from the file list
   * @param {number} chipId
   */
  const deleteFile = (chipId: number) => {
    // Add file to the "to be deleteted files" if it's in the original file list
    toBeDeletedCallback(filesList[chipId])
    // Delete file from the UI list
    const newFilesList = filesList.filter((chipEl, elIndex) => chipId !== elIndex)
    if (updateFilesListCallback) {
      updateFilesListCallback(newFilesList)
    }
  }

  /**
   * Builds the accepted extensions string from the files extensions list
   * If there's more than one extension, it results in comma-separated string
   * @returns Accepted extensions string for the file input
   */
  const buildAcceptedFileExtensions = () => {
    if (filesExtension.length === 1) {
      return `.${filesExtension[0]}`
    }
    const acceptedExtensions = filesExtension.map((extension) => `.${extension}`).join(',')
    return acceptedExtensions
  }

  const modalBtns = [
    <Button
      key={0}
      type="ghost"
      className={styles.btn}
      onClick={handleCancel}
      aria-label="cancel override files"
    >
      {t('scoreboard_configuration.ocr_files.modalCancelButton')}
    </Button>,
    <Button
      key={1}
      type="ghost"
      danger
      className={styles.btn}
      onClick={handleOk}
      aria-label="override files"
    >
      {t('scoreboard_configuration.ocr_files.modalOkButton')}
    </Button>,
  ]
  return (
    <div>
      {/* Drag & Drop area */}
      <div
        onDragEnter={() => {
          dragDropOverlayRef.current.style.visibility = 'visible'
          setDragOver(true)
        }}
        onDragOver={(e) => e.preventDefault()}
        onDrop={filesHandler}
        className={clsx({
          [styles.disabled]: disabled,
          [styles.areaOver]: dragOver,
          [styles.area]: true,
        })}
      >
        <div
          ref={dragDropOverlayRef}
          className={styles['dragDropArea-overlay']}
          onDragLeave={() => {
            dragDropOverlayRef.current.style.visibility = 'hidden'
            setDragOver(false)
          }}
        />
        <div className={styles.contentArea}>
          <Icon
            component={IconNames.UPLOAD}
            size={32}
            className={dragOver ? styles.instructionsOver : ''}
          />
          <span
            className={`body-medium semibold ml-2 ${
              disabled ? styles.instructionsDisabled : dragOver ? styles.instructionsOver : ''
            }`}
          >
            {t('scoreboard_configuration.ocr_files.content')}
          </span>
          <Button
            type="ghost"
            onClick={() => dragDropInput.current.click()}
            aria-label="upload files"
            disabled={disabled}
            className={styles.button}
          >
            {t('scoreboard_configuration.ocr_files.uploadButton')}
          </Button>
        </div>
        <div className={styles.input}>
          <input
            type="file"
            aria-label="upload files input"
            accept={buildAcceptedFileExtensions()}
            ref={dragDropInput}
            onChange={filesHandler}
            multiple={multiple}
          ></input>
        </div>
      </div>
      {/* Uploaded files tags */}
      <div className={styles.tagsbox}>
        {filesList.map((chip, chipId) => {
          return (
            <Tag closable onClose={() => deleteFile(chipId)} key={chip} data-testid={`tag-${chip}`}>
              {chip}
            </Tag>
          )
        })}
      </div>
      {/* MODAL */}
      <NotificationModal
        titleIconType={IconType.INFO}
        title={t('scoreboard_configuration.ocr_files.modalTitle')}
        body={
          <>
            <p>{t('scoreboard_configuration.ocr_files.modalMessage')}</p>
            <ul className={styles.list}>
              {repeatedFiles.map((file, fileID) => {
                return <li key={fileID}>{file.name}</li>
              })}
            </ul>
          </>
        }
        footerBtns={modalBtns}
        open={openModal}
        onOk={handleOk}
        width={438}
        centered
        onCancel={handleCancel}
        footer={null}
        cancelText={t('scoreboard_configuration.ocr_files.modalCancelButton')}
        okText={t('scoreboard_configuration.ocr_files.modalOkButton')}
      />
    </div>
  )
}
