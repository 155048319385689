import { Button, Col } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { SportCalibration, SportDTO, SportGroupDTO } from 'api/api_code'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { getSportIllus } from 'helpers/utils'
import useTranslation from 'next-translate/useTranslation'
import styles from './SportCard.module.css'

enum SportCardHandlerType {
  CONFIG = 'CONFIG',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

interface SportCardProps {
  sport: SportDTO | SportGroupDTO | SportCalibration
  btnHandler: (
    type: SportCardHandlerType,
    sport: SportDTO | SportGroupDTO | SportCalibration
  ) => void
  editOrDeleteButtons?: boolean
}

const SportCard = ({ sport, btnHandler, editOrDeleteButtons = false }: SportCardProps) => {
  const { t } = useTranslation('fieldConfig')

  const getIdentifier = (sport: SportDTO | SportGroupDTO | SportCalibration) => {
    return (sport as SportCalibration).sport?.uuid || (sport as SportGroupDTO).name
  }

  const getSportName = (sport: SportDTO | SportGroupDTO | SportCalibration) => {
    if ('name' in sport) {
      return (sport as SportGroupDTO).name
    } else if ('sport' in sport) {
      return (sport as SportCalibration).sport.name
    } else {
      return (sport as SportCalibration).sportfield_name
    }
  }

  return (
    <Col
      key={getIdentifier(sport)}
      className={`${styles.box} m-1`}
      data-testid={`sport-${getSportName(sport)}`}
    >
      <Icon
        component={getSportIllus(getIdentifier(sport))}
        className={styles.sportIcon}
        size={56}
      ></Icon>
      {editOrDeleteButtons && (
        <div className={styles.buttonBox}>
          <Button
            type="primary"
            size="middle"
            aria-label="Edit sport"
            shape="circle"
            className={styles.roundBtn}
            onClick={() => btnHandler(SportCardHandlerType.EDIT, sport)}
          >
            <Icon component={IconNames.EDIT} size={24} />
          </Button>

          <Button
            type="primary"
            size="middle"
            shape="circle"
            aria-label="Delete sport"
            className={styles.roundBtn}
            onClick={() => btnHandler(SportCardHandlerType.DELETE, sport)}
          >
            <Icon component={IconNames.TRASH} size={24} />
          </Button>
        </div>
      )}
      {!editOrDeleteButtons && (
        <Button
          type="primary"
          size="small"
          aria-label="Configure sport"
          className={styles.btn}
          onClick={() => btnHandler(SportCardHandlerType.CONFIG, sport)}
        >
          {t('configured_sports.calibrateButton')}
        </Button>
      )}
      <Paragraph>{getSportName(sport).toUpperCase()}</Paragraph>
    </Col>
  )
}

export { SportCard, SportCardHandlerType }
