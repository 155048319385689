import { Button, Divider } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import { SelectSportFieldModal } from 'components/UI/Modals/SelectSportFieldModal/SelectSportFieldModal'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { SubSportModal } from 'components/UI/Modals/SubSportModal/SubSportModal'
import { useFieldConfig } from 'hooks/useFieldConfig'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './SportsConfig.module.css'
import { useRouter } from 'next/router'
import { SportCard, SportCardHandlerType } from './SportCard'
import { CalibrationEndpoints, OptionsEndpoints } from 'api/axios/axiosAPIGroups'
import { SportCalibration, SportDTO, SportGroupDTO } from 'api/api_code'
import { setToSessionStorage } from 'helpers/storageUtils'
import { ConfirmationModal } from 'components/UI/Modals/ConfirmationModal'
import { IconType, NotificationModal } from 'components/UI/Modals/NotificationModal'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'

const NoConfiguredSports = () => {
  const { t } = useTranslation('fieldConfig')

  return (
    <div className={styles.emptyBox}>
      <Icon component={IconNames.KO} size={32}></Icon>
      <Paragraph className={styles.parag}>
        {t('configured_sports.noSportsConfiguredMessage')}
      </Paragraph>
    </div>
  )
}

export const SportsConfig = () => {
  const { t } = useTranslation('fieldConfig')
  const { t: tCommon } = useTranslation('common')
  const [openSportModal, setOpenSportModal] = useState<SportGroupDTO>(null)
  const [openSelectSportFieldModal, setOpenSelectSportFieldModal] = useState<SportDTO>(null)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const toDeleteSportUUID = useRef<string>(null)
  const { fieldConfig, setFieldConfig, currentStep } = useFieldConfig()
  const router = useRouter()
  const device_uuid = router.query['device_uuid']
  const field_uuid = router.query['field_uuid']
  const [errorSave, setErrorSave] = useState<boolean>(false)
  const { requestHandler } = useHTTPRequestHandler()

  const [availableSports, setAvailableSports] = useState<Array<SportGroupDTO>>()

  /**
   * Generates a set with the UUIDs of calibrated sports
   * @param {Set<string>} configuredsports Calibrated sports
   */
  const getUuids = (configuredsports: SportCalibration[]) => {
    const result = new Set<string>()
    if (configuredsports)
      configuredsports.map((configuredSport) => result.add(configuredSport.sport.uuid))
    return result
  }

  /**
   * Generates an object with uuids of sports as keys, and
   * names of sports as values
   * @param {Array<SportGroupDTO>} groups Groups of sports
   */
  const getNamesByUuid = (groups: Array<SportGroupDTO>) => {
    const result = new Set<string>()
    if (groups?.length) {
      groups.forEach((group) => {
        group.sports.forEach((sport) => {
          result.add(sport.uuid)
        })
      })
    }
    return result
  }

  const uuidConfigSports = useMemo(
    () => getUuids(fieldConfig?.configured_sports),
    [fieldConfig?.configured_sports]
  )

  const availableSportsUUID = useMemo(() => getNamesByUuid(availableSports), [availableSports])

  const getSportName = (sport: SportDTO | SportGroupDTO | SportCalibration) => {
    return (sport as SportGroupDTO).name || (sport as SportCalibration).sport.name
  }

  useEffect(() => {
    requestHandler({
      requestPromise: OptionsEndpoints.getSportsDevicesDeviceUuidOptionsSportsGet(
        device_uuid as string
      ),
      onOkCallback: (availableSports: SportGroupDTO[]) => {
        let generic = null
        const newAvailableSports = availableSports.filter((sport) => {
          if (sport.name !== 'Generic') return availableSports
          generic = sport
        })
        generic && newAvailableSports.push(generic)
        setAvailableSports(newAvailableSports)
      },
    })
  }, [])

  // if sport name is generic or if the sport has many sportfields, show sportfield Modal,
  // if sport has subsports, open subSport Modal,
  // else open calibrate page
  const calibrateSport = (
    type: SportCardHandlerType,
    sport: SportDTO | SportGroupDTO | SportCalibration
  ) => {
    switch (type) {
      case SportCardHandlerType.CONFIG:
        // All the types in CONFIG are SportGroupDTO
        if (getSportName(sport) === 'Generic')
          setOpenSelectSportFieldModal((sport as SportGroupDTO).sports[0])
        else if ((sport as SportGroupDTO).sports.length > 1) {
          // More than one sport in the sport group
          setOpenSportModal(sport as SportGroupDTO)
        } else if ((sport as SportGroupDTO).sports[0].sportfields.length > 1) {
          // Only one sport in the sport group with multiple sportfields
          setOpenSelectSportFieldModal((sport as SportGroupDTO).sports[0])
        } else {
          // Only one sport in the sport group
          pushPage(
            getSportName(sport),
            (sport as SportGroupDTO).sports[0].sportfields[0].value,
            (sport as SportGroupDTO).sports[0].uuid,
            SportCardHandlerType.CONFIG
          )
        }

        break
      case SportCardHandlerType.EDIT:
        // All the types in EDIT are SportCalibration
        pushPage(
          getSportName(sport),
          (sport as SportCalibration).sportfield_name,
          (sport as SportCalibration).sport.uuid,
          SportCardHandlerType.EDIT
        )
        break
      case SportCardHandlerType.DELETE:
        // All the types in DELETE are SportCalibration
        toDeleteSportUUID.current = (sport as SportCalibration).sport.uuid
        setOpenDeleteModal(true)
        break
    }
  }

  const handleCancel = () => {
    setOpenSportModal(null)
  }

  const handleCancelSelectSportFieldModal = () => {
    setOpenSelectSportFieldModal(null)
  }

  /**
   * Pushes user to the selected sport calibration page.
   * @param {string} name Selected sport name
   * @param {string} sportfield_name Selected sportfield_name
   * @param {string} sport_uuid Sport identifier
   */
  const pushPage = (
    name: string,
    sportfield_name: string,
    sport_uuid: string,
    type: SportCardHandlerType
  ) => {
    requestHandler({
      requestPromise:
        CalibrationEndpoints.startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(
          device_uuid as string,
          sport_uuid,
          { sportfield_name: sportfield_name }
        ),
      onOkCallback: () => {
        // Save the sport_uuid & sportfield_name to persist state for browser reload
        setToSessionStorage('field-config-sport_uuid', sport_uuid)
        setToSessionStorage('field-config-sportfield_name', sportfield_name)
        setToSessionStorage('field-config-type', type)
        router.push(
          {
            pathname: '/camera/[device_uuid]/field/[field_uuid]/settings/[sport]/calibration',
            query: {
              device_uuid,
              field_uuid,
              sport: name,
              sportfield_name,
              sport_uuid,
              step: currentStep.index,
              type,
            },
          },
          `/camera/${device_uuid}/field/${field_uuid}/settings/${name}/calibration`
        )
      },
    })
  }

  const configuredSports = useMemo(() => {
    let generic = null
    const configuredSports = fieldConfig?.configured_sports.filter(
      (configuredSport: SportCalibration) => {
        if (configuredSport.sport.name !== 'GENERIC') return configuredSport
        generic = configuredSport
      }
    )
    generic && configuredSports.push(generic)
    return configuredSports
  }, [fieldConfig?.configured_sports])

  return (
    fieldConfig && (
      <div className={styles.content} data-testid="sports-configuration">
        <div className={styles.flexcolcenter} data-testid="sports-to-be-configured">
          <Title level={3} className="mt-4">
            {t('configured_sports.availableSportsTitle')}
          </Title>
          <div className={styles.flexcenter}>
            {availableSportsUUID.size === uuidConfigSports.size ? (
              <div className={styles.emptyBox}>
                <Icon component={IconNames.OK} size={32}></Icon>
                <Paragraph className={styles.parag}>
                  {t('configured_sports.allSportsConfiguredMessage')}
                </Paragraph>
              </div>
            ) : (
              <div className={styles.row}>
                {availableSports &&
                  availableSports.map((group) => {
                    const hasUncalibratedSports = group.sports.filter(
                      (subSport) => !uuidConfigSports.has(subSport.uuid)
                    )
                    if (hasUncalibratedSports.length === 0) return

                    return <SportCard key={group.name} sport={group} btnHandler={calibrateSport} />
                  })}
              </div>
            )}
          </div>
        </div>
        <Divider dashed type={'vertical'} className={styles.divider}></Divider>
        <div className={`${styles.flexcolcenter}`} data-testid="configured-sports">
          <Title level={3} className="mt-4">
            {t('configured_sports.configuredSportsTitle')}
          </Title>
          <div className={styles.flexcenter}>
            {uuidConfigSports.size === 0 ? (
              <NoConfiguredSports />
            ) : (
              <div className={styles.row}>
                {configuredSports &&
                  configuredSports.map((configuredSport) => (
                    <SportCard
                      key={configuredSport.sport.uuid}
                      sport={configuredSport}
                      btnHandler={calibrateSport}
                      editOrDeleteButtons
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
        <SubSportModal
          sport={openSportModal}
          configuredUuids={uuidConfigSports}
          onCancel={handleCancel}
          onSelectSubSport={(index) => {
            // Open Sport Field Modal if the sport has more than one sportfield
            if (openSportModal.sports[index].sportfields.length > 1) {
              setOpenSelectSportFieldModal(openSportModal.sports[index])
            } else {
              pushPage(
                openSportModal.sports[index].name,
                openSportModal.sports[index].sportfields[0].value,
                openSportModal.sports[index].uuid,
                SportCardHandlerType.CONFIG
              )
            }
            setOpenSportModal(null)
          }}
        />
        <SelectSportFieldModal
          sport={openSelectSportFieldModal}
          onCancel={handleCancelSelectSportFieldModal}
          onSelectSportField={(sportField: string) => {
            pushPage(
              openSelectSportFieldModal.name,
              sportField,
              openSelectSportFieldModal.uuid,
              SportCardHandlerType.CONFIG
            )
            setOpenSelectSportFieldModal(null)
          }}
        />
        <ConfirmationModal
          modalState={openDeleteModal}
          confirmTitleText={tCommon('deleteConfigSport')}
          confirmQuestionText={tCommon('deleteConfigSportConfirm')}
          explanationText={tCommon('deletePress')}
          cancelText={tCommon('cancel')}
          confirmText={tCommon('delete')}
          closeCallback={() => {
            setOpenDeleteModal(false)
          }}
          confirmCallback={() => {
            requestHandler({
              requestPromise:
                CalibrationEndpoints.deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(
                  device_uuid as string,
                  toDeleteSportUUID.current
                ),
              onOkCallback: () => {
                setFieldConfig((prevFieldConfig) => ({
                  ...prevFieldConfig,
                  configured_sports: prevFieldConfig.configured_sports.filter(
                    (item) => item.sport.uuid !== toDeleteSportUUID.current
                  ),
                }))
                toDeleteSportUUID.current = null
              },
            })
          }}
        />
        <NotificationModal
          titleIconType={IconType.KO}
          title={tCommon('errors.request.title')}
          body={<p>{tCommon('errors.request.subtitle')}</p>}
          open={errorSave}
          onOk={() => setErrorSave(false)}
          width={438}
          centered
          onCancel={() => setErrorSave(false)}
          footer={null}
          footerBtns={[
            <Button key={0} type="ghost" aria-label="Error" onClick={() => setErrorSave(false)}>
              {tCommon('ok')}
            </Button>,
          ]}
        />
      </div>
    )
  )
}
