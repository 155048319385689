import { Button, Modal, ModalProps } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { ReactElement } from 'react'
import { Icon } from '../Icon/Icon'
import { IconNames } from '../Icon/icons'
import styles from './NotificationModal.module.css'

enum IconType {
  OK = 'OK',
  KO = 'KO',
  INFO = 'INFO',
  RECORDING = 'RECORDING',
}

interface NotificationModalProps extends ModalProps {
  /**
   * Specifies the notification type
   */
  titleIconType?: IconType

  /**
   * Notification modal bold title
   */
  title?: string

  /**
   * Modal body content
   */
  body?: ReactElement

  /**
   * Cusotm footer buttons, overrides AntD's
   */
  footerBtns?: ReactElement[]
}

interface OpenNotificationProps extends NotificationModalProps {
  open?: boolean
}

const defaultFooterBtns = (onOK) => {
  return [
    <Button key={0} aria-label="ok error" type="ghost" onClick={onOK}>
      Ok
    </Button>,
  ]
}

const NotificationModal = ({
  titleIconType = IconType.INFO,
  title = 'Notification',
  body,
  open,
  onOk,
  onCancel,
  cancelText,
  okText,
  footerBtns = defaultFooterBtns(onOk),
  closable = true,
}: NotificationModalProps) => {
  const getIcon = {
    [IconType.OK]: IconNames.OK,
    [IconType.KO]: IconNames.KO,
    [IconType.INFO]: IconNames.INFO,
    [IconType.RECORDING]: IconNames.RECORDING,
  }

  const getIconBox = {
    [IconType.OK]: styles.iconBoxOK,
    [IconType.KO]: styles.iconBoxKO,
    [IconType.INFO]: styles.iconBoxInfo,
    [IconType.RECORDING]: styles.iconBoxKO,
  }

  return (
    <Modal
      open={open}
      onOk={onOk}
      width={438}
      centered
      onCancel={onCancel}
      footer={null}
      cancelText={cancelText}
      okText={okText}
      className={styles.modal}
      closeIcon={<Icon component={IconNames.CROSS} size={24}></Icon>}
      closable={closable}
    >
      <div className={styles.flexcolcenter}>
        <div className={getIconBox[titleIconType]}>
          <Icon component={getIcon[titleIconType]} size={28} />
        </div>
        <Title level={2} className={styles.modalTitle}>
          {title}
        </Title>
        {body}
        <div className={styles.footerBtn}>{footerBtns.map((btn: ReactElement) => btn)}</div>
      </div>
    </Modal>
  )
}

export { NotificationModal, type NotificationModalProps, IconType, type OpenNotificationProps }
